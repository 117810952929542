import { inject, ChangeDetectorRef, isSignal } from '@angular/core';
import { isObservable, of, EMPTY } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { wrapIntoObservable } from 'ng-zorro-antd/core/util';

/**
 * Check if two arrays are equal
 *
 * @param arr1
 * @param arr1
 */
const isEqualArray = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  const length = arr1.length;
  const comparedIndexes = Array.from({
    length
  }).fill(false);
  for (let item1Index = 0; item1Index < length; item1Index++) {
    const arr2Index = arr2.findIndex((item2, item2Index) => {
      return isEqual(arr1[item1Index], item2) && !comparedIndexes[item2Index];
    });
    if (arr2Index === -1) {
      return false;
    }
    comparedIndexes[arr2Index] = true;
  }
  return true;
};

/**
 * Check if two objects are equal
 *
 * @param obj1
 * @param obj2
 */
const isEqualObject = (obj1, obj2) => {
  if (obj1 === null && obj2 === null) {
    return true;
  }
  if (obj1 === null || obj2 === null) {
    return false;
  }
  const obj1Entries = Object.entries(obj1);
  const obj2Entries = Object.entries(obj2);
  if (obj1Entries.length !== obj2Entries.length) {
    return false;
  }
  for (const [obj1Key, obj1Value] of obj1Entries) {
    const [_, obj2Value] = obj2Entries.find(([obj2Key]) => obj1Key === obj2Key) ?? ['', null];
    if (!isEqual(obj1Value, obj2Value)) {
      return false;
    }
  }
  return true;
};

/**
 * Check if two values are equal
 *
 * @param v1
 * @param v2
 */
const isEqual = (v1, v2) => {
  if (typeof v1 !== typeof v2) {
    return false;
  }
  if (Array.isArray(v1) && Array.isArray(v2)) {
    return isEqualArray(v1, v2);
  }
  if (typeof v1 === 'object' && typeof v2 === 'object') {
    return isEqualObject(v1, v2);
  }
  return v1 === v2;
};
const crmDetectChangesFn = () => {
  const ref = inject(ChangeDetectorRef);
  return () => ref.detectChanges();
};

/* eslint-disable @typescript-eslint/naming-convention */
function crmExpression(inputs) {
  const {
    expression,
    source,
    initialValue,
    injector
  } = inputs;
  if (typeof expression === 'function') {
    return resolveExpression({
      expression: expression(source),
      initialValue,
      injector
    });
  }
  return resolveExpression({
    injector,
    initialValue,
    expression
  });
}
const resolveExpression = inputs => {
  const {
    expression,
    initialValue,
    injector
  } = inputs;
  let $ = null;
  if (isObservable(expression)) {
    $ = expression;
  }
  if (isSignal(expression)) {
    $ = toObservable(expression, {
      injector
    });
  }
  if ($ == null) {
    if (expression == null) {
      $ = initialValue ? of(initialValue) : EMPTY;
    } else {
      $ = wrapIntoObservable(expression);
    }
  }
  return $;
};
const crmMarkForCheckFn = () => {
  const ref = inject(ChangeDetectorRef);
  return () => ref.markForCheck();
};

/**
 * Generated bundle index. Do not edit.
 */

export { crmDetectChangesFn, crmExpression, crmMarkForCheckFn, isEqual, isEqualArray, isEqualObject };
